var AwesomeFilter = function (options) {
	'use strict';

	var $ = jQuery; // enable jQuery
	var self = this;

	self.options = options;

	self.bind = function () {
		$(self.options.control_selector, self.options.controls).on('click', function () {
			var filter = $(this).attr('data-filter');
			self.filter(filter);
		});
	}

	self.filter = function (filter) {
		filter = $('.filter-item.awesome-filter-' + filter, self.options.container).length > 0 ? filter : '*';

		var filter_element = filter == '*' ? '*' : '.awesome-filter-' + filter;
		$('.filter-item ' + self.options.control_selector, self.options.controls).removeClass(self.options.control_active);
		$('.filter-item [data-filter="' + filter + '"]').addClass(self.options.control_active);

		$('.filter-item', self.options.container).addClass('la-hidden');
		$('.filter-item' + filter_element, self.options.container).removeClass('la-hidden');
		$('.filter-item').trigger('cssClassChanged');
	}

	self.init = function () {
		var active = options.active || '*';

		self.bind();
		self.filter(active);
	}
}