var ATS = window.ATS || (function ($) {
        'use strict';

        var slider_fallbacks = function (count) {
            return [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: count,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: count < 8 ? count : 8,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: count < 6 ? count : 6,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: count < 5 ? count : 5,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 667,
                    settings: {
                        slidesToShow: count < 4 ? count : 4,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: count < 3 ? count : 3,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: count < 2 ? count : 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];
        };

        jQuery(document).ready(function () {

            var os_name = AwesomeUtil.detect_os(); // detect user OS
            if (os_name == 'MacOS') $('html').addClass('mac');


            var qd = {};
            location.search.substr(1).split("&").forEach(function (item) {
                (item.split("=")[0] in qd) ? qd[item.split("=")[0]].push(item.split("=")[1]) : qd[item.split("=")[0]] = [item.split("=")[1]];
            });
            var team_id = qd["team_id"];
            var employer_id = qd["employee_id"];
            var $team = jQuery('#ats-layout-' + team_id);
            var $employer = jQuery('[team-id="' + team_id + '"].employers-box > li[employer-id="' + employer_id + '"]');
            if ($team.length > 0 && $employer.length > 0) {
                $('html, body').animate({
                    scrollTop: $team.offset().top
                }, 1000);
                setTimeout(function () {
                    $employer.trigger('click');
                }, 1000);
            }
        });

        function enable_slider(id, options) {
            id = parseInt(id);
            var count = parseInt(options.count),
                autoplay = options.autoplay == 1 ? true : false || false,
                speed = parseInt(options.speed),
                adaptive = options.adaptive == 1 ? true : false || false;
            window['ats_slider_' + id] = jQuery('#ats-layout-' + id + ' > .slider-enabled').slick({
                speed: 1000,
                draggable: false,
                slidesToShow: count,
                mobileFirst: true,
                slidesToScroll: count,
                autoplay: autoplay,
                autoplaySpeed: speed,
                adaptiveHeight: adaptive,
                prevArrow: '<div type="button" title="Previous" class="ats-button slick-prev"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-left"></use></svg></div>',
                nextArrow: '<div type="button" title="Next" class="ats-button slick-next"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-right"></use></svg></div>',
                responsive: slider_fallbacks(count)
            });
        }

        function init_filter(id, active_term) {
            var ats_filter = new AwesomeFilter({
                container: $('[team-id="' + id + '"].filter-container'),
                controls: $('[team-id="' + id + '"].filter-controls'),
                item_selector: '.isotope-item',
                control_selector: '.ats-button',
                control_active: 'ats-button-active',
                attribute: 'team-id',
                active: active_term,
                id: id
            });
            ats_filter.init();
        }

        function bind_profiles(id) {
            $('[team-id="' + id + '"] [ats-profile]').on('click', function () {
                var url = $(this).attr('data-profile');
                window.open(url, '_self');
            })
        }

        function init_animations(id) {
            id = parseInt(id);
            var $container = $('#ats-layout-' + id);
            $container.addClass('ats-animations-enabled');

            AwesomeViewport.add({
                element: $container[0],
                threshold: '30%',
                enter: function () {
                    $(this).addClass('ats-transition__in');
                }
            });

            $container.find('li').each(function (i, el) {
                var $t = $(this);
                var name = get_name($t.find('.employer_name .team-field-content').text());
                if (!name) return;
                $(this).data('name', name).find('.photo-container').attr('data-name', 'Meet &#xa; ' + name).parent().append('<span class="ats-img-caption"><strong>Meet</strong><br>' + name + '</span>');
            });
        }

        function get_name(str) {
            var result;
            // get first word, assuming it's first name
            var index = str.indexOf(" ");
            result = index === -1 ? str : str.substring(0, index);

            return result;
        }


        function init_panel(id, animation) {
            $('[team-id="' + id + '"].employers-box > li').on('click', function (e) {
                if (e.target.nodeName.toLowerCase() == 'a') {
                    return;
                }
                var team_id = $(this).parents('.employers-box').attr('team-id');
                var employer_id = $(this).attr('employer-id');
                var prefix = team_id + '_' + employer_id;

                if (typeof window['ats_' + prefix + '_panel'] != 'object' && !window['la_panel_opened']) {
                    var ats_panel = new AwesomePanel({
                        id: 'ats_' + prefix,
                        direction: 'left',
                        width: '500px',
                        height: '100%',
                        animation: animation,
                        html: '#ats_employer_panel_' + prefix
                    });
                    ats_panel.init();
                }
            });
        }

        function init_search(id, slider) {
            $('#ats-layout-' + id + ' [data-action="employers-search"]').on('input', function () {
                var query = $(this).val().trim().toLowerCase();
                if(slider){
                    window['ats_slider_' + id].slick('slickUnfilter');
                    window['ats_slider_' + id].slick('slickFilter', function () {
                        var title = $(this).data("title").toLowerCase() || "";
                        if (title.indexOf(query) !== -1) {
                            return true;
                        }
                        return false;
                    });
                }else {
                    var $team = $(this).parents('.ats-layout').get(0);
                    var employers = $('ul[team-id].employers-box > li', $team);
                    employers.hide();
                    $.each(employers, function (index, item) {
                        var title = $(item).data("title").toLowerCase() || "";
                        if (title.indexOf(query) !== -1) {
                            $(item).show();
                        }
                    });
                }
            });

            $('#ats-layout-' + id + ' [data-action="clear-search"]').on('click', function () {
                $('#ats-layout-' + id + ' [data-action="employers-search"]').val('').trigger('input');
            });
        }

        function paint_rows(id) {
            $('[team-id="' + id + '"].employers-box > li').removeClass('even_background_color');
            var visible = $('[team-id="' + id + '"].employers-box > li').not('.la-hidden');
            $.each(visible, function (index, item) {
                if ((index + 1) % 2 === 0) {
                    $(item).addClass('even_background_color');
                }
            });
        }

        function calc_rating_icons_size(id) {
            var team = $('#ats-layout-' + id);
            $('.employer_skills .team-field-content', team).css('max-width', 'auto');

            var on_resize_handler = function () {
                var employers = $('li[employer-id]', team);
                var fill_max_width = $('.employer_skills:visible', team).outerWidth();
                fill_max_width = Math.round(fill_max_width / 10) * 10;
                $('.employer_skills .team-field-content', team).css('max-width', fill_max_width);
                var icon_max_width = $('li .star-background:visible > *', team).outerWidth();

                $(employers).each(function (index, item) {
                    var employer = $(item);
                    var skills = $('.employer_skills ul li', employer);
                    skills.each(function (index, item) {
                        var rating = parseInt($(item).attr('data-rating'));
                        $('.star-fill', employer).css('max-width', fill_max_width * rating / 100);
                        $('.star-background > *', employer).each(function (index, item) {
                            $(item).css('max-height', icon_max_width);
                        });
                        $('.star-fill > *', employer).each(function (index, item) {
                            $(item).css('max-width', icon_max_width);
                            $(item).css('max-height', icon_max_width);
                        });
                    });
                });
            };
            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        function calc_social_icons_size() {
            /*var groups = $('.aet-layout');
             $('.aet-layout .testimonial_social .group-field-content').css('max-width', 'auto');

             var on_resize_handler = function () {
             groups.each(function (index, item) {
             var group = $(this);
             var testimonials = $('.testimonials-social > li, li .testimonial_social', group);
             var fill_max_width = $('li .group-field-content:visible', group).outerWidth();
             fill_max_width = Math.round(fill_max_width / 10) * 10;
             $('.group-field-content', $(testimonials)).css('max-width', fill_max_width);
             var icon_max_width;
             var count = 0;

             $(testimonials).each(function (index, item) {
             var icons = $('.group-field-content > a', item);
             count = icons.length > count ? icons.length : count;
             });

             $(testimonials).each(function (index, item) {
             var icons = $('.group-field-content > a', item);
             icon_max_width = fill_max_width / count;

             $(icons).each(function (index, item) {
             $(item).css('max-width', icon_max_width);
             $(item).css('max-height', icon_max_width);
             });
             });
             });
             };
             on_resize_handler();
             $(window).resize(function () {
             on_resize_handler();
             });*/
        }


        return {
            'enable_slider': enable_slider,
            'init_animations': init_animations,
            'init_filter': init_filter,
            'init_panel': init_panel,
            'init_search': init_search,
            'bind_profiles': bind_profiles,
            'paint_rows': paint_rows,
            'calc_rating_icons_size': calc_rating_icons_size,
            'calc_social_icons_size': calc_social_icons_size
        };
    })(jQuery);

